import React from 'react';
import {
  Accordion,
  Column,
  Container,
  Layout,
  LayoutBlock,
  Hero,
  Title,
  Description,
  Row,
  Section,
  Actions,
} from '@dragonchain-dev/dragon-kit';
import { Layout as PageLayout, SEO, TypeForm, FeatureRow } from '../../components/_index';

const AntiFraud = () => {
  return (
    <PageLayout>
      <SEO
        title="Anti-Fraud Systems"
        description="Reduce fraud opportunities and provide transparency to your customers."
      />
      <Layout dark background="blue-dark-fade purple-effect">
        <Hero type="code">
          <div className="caption">Proof</div>
          <Title color="white">Anti-Fraud and Compliance</Title>
          <Description color="blue-light">
            Prove that you are following the compliance policies that you already have in place with
            Dragonchain blockchain based proof and assurance systems.
          </Description>
        </Hero>
      </Layout>

      <LayoutBlock separator>
        <Container>
          <Row gap="small">
            <Column span={8}>
              <Section
                subtitle="Prove Compliance in Payments and Market Systems"
                title="Fintech"
                footer={<TypeForm buttonType="button" label="See a case study" url="/case-studies/fintech" />}
              >
                Trust and security is important in the financial world. Your customers have already paid for
                regulatory compliance systems, with a simple Dragonchain integration they can prove it.
              </Section>
            </Column>
          </Row>
          <FeatureRow
            features={[
              {
                title: 'Market Manipulation',
                body: 'Prevent market manipulation including front running and wash trading.',
              },
              {
                title: 'Metrics',
                body: 'Prove volume, reserves, order books, or any other metrics to your customers.',
              },
              {
                title: 'Regulatory Compliance',
                body:
                  'Prove to partners and regulators that you are following pre-existing corporate policies and procedures.',
              },
              {
                title: 'Payments Systems',
                body:
                  'Make traditional bank-to-bank financial transactions more secure, for card payments, mobile wallets, and chargebacks.',
              },
              {
                title: 'Banking and Financial Regulations',
                body: 'Proof of compliance for all necessary regulatory requirements.',
              },
              {
                title: 'Controlled Transparency',
                body:
                  'Provide a refined level of transparency to your users without exposing sensitive data.',
              },
              {
                title: 'KYC',
                body: 'Simplify required identity capture.',
              },
              {
                title: 'AML',
                body: 'Automate compliance reporting with independent measure of proof in USD.',
              },
            ]}
          />
        </Container>
        <Container>
          <Row gap="small">
            <Column span={8}>
              <Section
                subtitle="Prove Traceability and Transparency"
                title="Supply Chain and Chain of Custody"
                
              >
                Reach out to us about a custom Supply Chain or Chain of Custody solution.
              </Section>
            </Column>
          </Row>
          <FeatureRow
            features={[
              {
                title: 'Traceability',
                body: 'Enhance accountability in complex supply chains vulnerable to fraud.',
              },
              {
                title: 'Controlled Transparency',
                body: 'Selectively expose all or part of any transaction class to partners or customers.',
              },
            ]}
          />
        </Container>
        <Container>
          <Row gap="medium">
            <Column span={8}>
              <Section
                subtitle="Prove Safety of User Data"
                title="Identity"
                
              >
                Reach out to us about a custom Identity solution.
              </Section>
            </Column>
          </Row>
          <FeatureRow
            features={[
              {
                title: 'GDPR/CCPA Capable',
                body: 'Integrate your existing approved policies with proof.',
              },
              {
                title: 'Authentication',
                body: 'Improve security of user account access.',
              },
              {
                title: 'Prevent Impersonation',
                body: 'Verifiable identification of all parties in communications with users.',
              },
              {
                title: 'Data Breaches',
                body: 'Prevent centralized access to personal identity information.',
              },
              {
                title: 'Usability',
                body: 'Grandma safe identity recovery and management.',
              },
              {
                title: 'Decentralized Identity',
                body:
                  'Improve privacy and reduce liability associated with user’s PII, HIPAA, and financial data.',
              },
            ]}
          />
        </Container>
        <Container>
          <Row gap="small">
            <Column span={8}>
              <Section
                subtitle="Prove Manufacturing Processes"
                title="IoT and Sensor Data"
                footer={
                  <TypeForm buttonType="button" label="See a case study" url="/case-studies/supply-chain" />
                }
              >
                Reach out to us about a custom IoT solution.
              </Section>
            </Column>
          </Row>
          <FeatureRow
            features={[
              {
                title: 'Manufacturing',
                body: 'Maintain a record of state in your manufacturing process.',
              },
              {
                title: 'Product Recall Management',
                body: 'Tighten the effects of a safety recall.',
              },
              {
                title: 'SIM Fraud',
                body: 'Prevent device based fraud by tying to a multi-factor decentralized identity.',
              },
            ]}
          />
        </Container>
        <Container>
          <Row gap="large">
            <Column span={12}>
              <Accordion title="Frequently Asked Questions">
                <Accordion.Item title="Does blockchain fix every fraud and security issue?">
                  With the implementation of 5G and more and more Internet of Things (IoT) devices connected
                  to the internet everyday, the protection and security of billions of data records needs
                  continuous attention. Blockchain does not solve every fraud case or security issue, but is
                  part of a holistic solution that includes AI and machine learning for better detection and
                  prevention of fraud.
                </Accordion.Item>
                <Accordion.Item title="How is Dragonchain's blockchain technology used by exchanges?">
                  We are live, in production, with a cryptocurrency exchange providing them with proof of
                  trades and proof of orders, making them the most transparent financial service in the world
                  to date. Through the use of APIs, Dragonchain flawlessly integrated with the existing
                  systems of the exchange to eliminate front running and offer full immutable proof of origin
                  to their customers.
                </Accordion.Item>
                <Accordion.Item title="Which sectors are common in fraud?">
                  Due to lack of transparency, outdated (cyber) security, and crimes of opportunity fraud
                  touches every industry. The most costly fraud occurs in banking and payment services,
                  manufacturing, energy, construction, transportation and warehousing, communications and
                  publishing, wholesale, retail, utilities, government and public administrations, healthcare,
                  and others.
                </Accordion.Item>
              </Accordion>
            </Column>
          </Row>
          <Row>
            <Column span={6}>
              <Section title="Blockchain Based Anti-Fraud and Compliance Systems">
                Integrate blockchain based anti-fraud solutions with your existing anti-fraud and compliance
                solutions. 83% of Executives are looking for blockchain solutions to the most pressing issues
                facing their businesses but are disappointed with what is available.
              </Section>
            </Column>
            <Column span={6} right middle>
            <Actions
            actions={[
              {
                type: 'link',
                name: 'Contact Us',
                color: 'blue',
                hover: 'blue-dark',
                to: '/contact-us',
              },
            ]}
          />
            </Column>
          </Row>
        </Container>
      </LayoutBlock>
    </PageLayout>
  );
};

export default AntiFraud;
